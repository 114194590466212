import CancelIcon from '@mui/icons-material/Cancel';
import React, { useState } from 'react';
import { Box, Button, Card, Grid, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { useSnackBar } from '../../context/snackBarContext';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import { DeleteOutline } from '@mui/icons-material';
import ConfirmDialog from '../shared/confirmDialog';

export function MessageContent({ messageData }) {
  const { setSnackbar } = useSnackBar();
  const navigate = useNavigate();

  const [openDeletePopup, setOpenDeletePopup] = useState(false);

  const handleDeleteClick = (message) => {
    setOpenDeletePopup(true);
  };

  async function deleteMessage() {
    try {
      await axios.delete(`messages`, { data: [messageData.id] });
      navigate(-1);
      setSnackbar({ open: true, message: 'Message deleted successfully', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Error in deleting message.', severity: 'error' });
    }
    setOpenDeletePopup(false);
  }

  return (
    <Grid item xs={12} mb={2}>
      <Box display={'flex'} alignItems={'center'} mb={2}>
        <Typography sx={{ flexGrow: 1, display: 'inline-flex', justifyContent: 'start', gap: 1 }}>
          <Typography sx={{ fontWeight: 700 }}>Created By:</Typography>
          {messageData?.createdBy}
        </Typography>
        <Typography sx={{ flexGrow: 1, display: 'inline-flex', justifyContent: 'end', gap: 1 }}>
          <Typography sx={{ fontWeight: 700 }}>Date Received:</Typography>
          {dayjs(messageData?.createdDate).format('MMM DD YYYY [at] h:mm A')}
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'} mb={2}>
        <Typography
          sx={{ flexGrow: 1, display: 'inline-flex', justifyContent: 'start', gap: 1, alignItems: 'baseline' }}
        >
          <Typography sx={{ fontWeight: 700 }}> Subject: </Typography> {messageData?.subject}
        </Typography>
        <Button
          className="btn btn-blue"
          sx={{ height: 'fit-content' }}
          startIcon={<DeleteOutline />}
          //onClick={deleteMessage}
          onClick={() => handleDeleteClick(messageData)}
        >
          Delete
        </Button>
      </Box>
      <Card sx={{ px: 2, py: 4, borderRadius: 2 }} variant="outlined">
        {messageData?.content}
      </Card>
      <ConfirmDialog
        open={openDeletePopup}
        onClose={() => setOpenDeletePopup(false)}
        onConfirm={deleteMessage}
        title="Are you sure you want to delete ?"
      />
    </Grid>
  );
}

export function MessageNoteForm({ refetch }) {
  const { id } = useParams();
  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm({ defaultValues: { note: '' } });

  const { setSnackbar } = useSnackBar();

  async function onSubmit(formData) {
    try {
      await axios.post(`messages/${id}/notes`, formData.note, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setSnackbar({ message: 'Note added successfully', open: true, severity: 'success' });
      await refetch();
      reset({ note: '' });
    } catch (error) {
      setSnackbar({ message: 'Error adding note', open: true, severity: 'error' });
    }
  }

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography sx={{ fontWeight: 700 }}>Add Note:</Typography>
        <Controller
          name="note"
          control={control}
          render={({ field: { name, onChange, value }, fieldState: { error } }) => (
            <TextField
              name={name}
              helperText={error ? error.message : null}
              error={!!error}
              onChange={onChange}
              value={value}
              fullWidth
              multiline
              rows={4}
              placeholder="Add note"
            />
          )}
        />
        <Box display={'flex'} gap={2} mt={2}>
          <Button
            startIcon={<SaveIcon />}
            disabled={!isDirty}
            type="submit"
            className="btn btn-blue"
            sx={{ width: 100 }}
          >
            Add
          </Button>
          <Button
            type="button"
            disabled={!isDirty}
            className="btn btn-blue"
            startIcon={<CancelIcon />}
            sx={{ width: 100 }}
            onClick={() => reset({ note: '' })}
          >
            Clear
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export function MessageNotes({ messageNotes }) {
  return (
    <Box maxHeight={400} mt={2} overflow={'auto'} sx={{ backgroundColor: '#f7f7f7' }} borderRadius={2}>
      {messageNotes?.map((messageNote) => (
        <Note key={messageNote?.id} messageNote={messageNote} />
      ))}
    </Box>
  );
}

function Note({ messageNote }) {
  return (
    <div className="note">
      <div className="note-body">
        <Box>
          <Typography fontWeight={800} letterSpacing={'.02em'}>
            {messageNote?.addedBy}: {dayjs(messageNote?.addedOn).format('MMM DD YYYY [at] h:mm A')}
          </Typography>
        </Box>
        {messageNote?.note}
      </div>
    </div>
  );
}
