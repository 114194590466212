import { useParams } from 'react-router-dom';
import { useSnackBar } from '../../context/snackBarContext';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { MessageContent, MessageNoteForm, MessageNotes } from '../../components/Messages/message';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { queryClient } from '../../App';
import { QUERY_MESSAGES_UNREAD_COUNT } from '../../utils/masterData';

export function Message() {
  const { id } = useParams();
  const [messageData, setMessageData] = useState();
  const [error, setError] = useState(false);
  const { setSnackbar } = useSnackBar();

  useEffect(() => {
    (async () => {
      await fetchData();
      queryClient.invalidateQueries({ queryKey: QUERY_MESSAGES_UNREAD_COUNT });
    })();
  }, []);

  async function fetchData() {
    try {
      const response = await axios.get(`messages/${id}`);
      setMessageData(response.data);
    } catch (error) {
      setSnackbar({ message: 'Error getting message data' });
      setError(true);
    }
  }

  if (error) return <div>Error getting message data</div>;

  return (
    <>
      <Helmet>
        <title>Message</title>
      </Helmet>
      <Grid container>
        <MessageContent messageData={messageData} />
        <Grid item xs={12}>
          <MessageNoteForm refetch={fetchData} />
          <MessageNotes messageNotes={messageData?.messageNotes} />
        </Grid>
      </Grid>
    </>
  );
}
