import { Link, useLocation } from 'react-router-dom';
import { Breadcrumbs, Stack } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export default function BreadCrumbsCustom() {
  const location = useLocation();
  const page = location.pathname.split('/');
  const replacementPaths = {
    NewJob: 'New Job',
    'pt-quantities': 'PT Quantities',
    'cut-import': 'Cut Import',
    studrails_quantities: 'Stud Rails Quantities',
  };
  let currentLink = '';

  const crumbs = location.pathname
    .split('/')
    .filter((path) => path !== '')
    .map((path, i) => {
      // currentlink have the original value of the path.
      currentLink = currentLink + `/${path}`;
      // sanitize the path names... studrails_quantities -> 'Stud Rails Quantities'
      path = replacementPaths.hasOwnProperty(path) ? replacementPaths[path] : path;

      // default render as New Message
      if (page[page.length - 1] === path && page[page.length - 2]?.toLowerCase() === 'messages') {
        return (
          <span key={path} className="breadcrumbs-text">
            View Message
          </span>
        );
      }

      if (page[page.length - 1] === path) {
        return (
          <span key={path} className="breadcrumbs-text">
            {decodeURI(path)}
          </span>
        );
      }

      return <RenderLink key={path} currentLink={currentLink} path={path} />;
    });

  return (
    <Stack spacing={2} className="breadcrumbs">
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {
          <Link to="/" className="breadcrumbs-link" key={1}>
            Home
          </Link>
        }
        {crumbs}
      </Breadcrumbs>
    </Stack>
  );
}

function RenderLink({ currentLink, path }) {
  return (
    <Link to={currentLink} key={path} className="breadcrumbs-link">
      {path.length === 2 ? path.toUpperCase() : decodeURIComponent(path)}
    </Link>
  );
}
