import { useState, useRef, useEffect } from 'react';
import { Column, DataGrid, Selection } from 'devextreme-react/data-grid';
import { Box, Button, Checkbox, FormControlLabel, Grid, Link } from '@mui/material';
import dayjs from 'dayjs';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import { useSnackBar } from '../../context/snackBarContext';
import { queryClient } from '../../App';
import { QUERY_MESSAGES_UNREAD_COUNT } from '../../utils/masterData';
import { DeleteOutline } from '@mui/icons-material';
import ConfirmDialog from '../shared/confirmDialog';

export function MessagesDataGrid() {
  const dataGridRef = useRef(null);
  const { setSnackbar } = useSnackBar();
  const [error, setError] = useState(false);
  const [messages, setMessages] = useState(null);
  const [readOnlyCheck, setReadOnlyCheck] = useState(false);
  const [isMarkUnreadButtonDisabled, setIsMarkUnreadButtonDisabled] = useState(true);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(true);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedMessagesForDelete, setSelectedMessagesForDelete] = useState([]);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  async function fetchData() {
    try {
      const response = await axios.get('messages');
      setMessages(response.data);
    } catch (error) {
      setError(error);
    }
  }

  function dateReceivedColumnRender(props) {
    return dayjs(props.value).format('MMM DD YYYY [at] h:mm A');
  }

  function subjectColumnRender(props) {
    return (
      <Link underline="hover" component={RouterLink} to={props.data.id}>
        {props.value}
      </Link>
    );
  }

  function filterReadOnly(event) {
    const isChecked = event.target.checked;
    const dataGrid = dataGridRef.current.instance;

    if (isChecked) {
      dataGrid.filter(['dateRead', '=', null]);
    } else {
      dataGrid.clearFilter();
    }

    setReadOnlyCheck(isChecked);
  }

  function onRowPrepared(e) {
    if (e.data?.dateRead) {
      e.rowElement.style.fontWeight = 'normal';
      e.rowElement.style.fontStyle = 'normal';
    } else {
      e.rowElement.style.fontWeight = 'bold';
    }
  }

  function onSelectedRowKeysChange(value) {
    setIsMarkUnreadButtonDisabled(!value.length);
    setIsDeleteButtonDisabled(!value.length);
    const selectedRows = value.map((id) => messages.find((msg) => msg.id === id));
    setSelectedMessagesForDelete(selectedRows);
  }

  // Delete selected messages
  async function deleteMessages() {
    try {
      await axios.delete('messages', { data: selectedMessagesForDelete.map((msg) => msg.id) });

      await fetchData();
      await queryClient.invalidateQueries({ queryKey: QUERY_MESSAGES_UNREAD_COUNT });
      setSnackbar({ open: true, message: 'Messages deleted successfully', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Error deleting messages.', severity: 'error' });
    }
    setOpenDeleteDialog(false);
  }
  async function markMessageAsUnread() {
    if (dataGridRef.current?.instance) {
      const datagrid = dataGridRef.current.instance;
      const selectedRowMessagesId = datagrid.getSelectedRowKeys();
      try {
        await axios.put(`messages`, selectedRowMessagesId);
        await fetchData();
        await queryClient.invalidateQueries({ queryKey: QUERY_MESSAGES_UNREAD_COUNT });
        datagrid.clearSelection();
        setSnackbar({ open: true, message: 'Messages marked as unread successfully', severity: 'success' });
      } catch (error) {
        setSnackbar({ open: true, message: 'Error in making messages as unread.', severity: 'error' });
      }
    }
  }

  if (error) return <div>Error getting messages</div>;

  return (
    <Grid item xs={12}>
      <Box display={'flex'} gap={4} alignItems={'center'} mb={2} justifyContent={'space-between'}>
        <Box display={'flex'} gap={4} alignItems={'center'}>
          <Button
            startIcon={<MarkAsUnreadIcon />}
            className="btn btn-blue"
            disabled={isMarkUnreadButtonDisabled}
            onClick={markMessageAsUnread}
          >
            Mark as unread
          </Button>
          <FormControlLabel
            control={<Checkbox value={readOnlyCheck} onChange={filterReadOnly} />}
            label="Show only unread"
          />
        </Box>
        <Button
          startIcon={<DeleteOutline />}
          className="btn btn-blue"
          disabled={isDeleteButtonDisabled}
          onClick={() => setOpenDeleteDialog(true)}
        >
          Delete
        </Button>
      </Box>

      <DataGrid
        ref={dataGridRef}
        keyExpr={'id'}
        dataSource={messages}
        onSelectedRowKeysChange={onSelectedRowKeysChange}
        onRowPrepared={onRowPrepared}
      >
        <Selection mode="multiple" allowSelectAll showCheckBoxesMode="always" />
        <Column dataField="subject" caption="Subject" dataType="string" cellRender={subjectColumnRender} />
        <Column
          dataField="createdDate"
          caption="Date Received"
          dataType="datetime"
          width={400}
          cellRender={dateReceivedColumnRender}
        />
        <Column dataField="dateRead" caption="DateRead" visible={false} />
      </DataGrid>

      <ConfirmDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onConfirm={deleteMessages}
        title="Are you sure you want to delete ?"
      />
    </Grid>
  );
}
