import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { MessagesDataGrid } from '../../components/Messages/messagesDataGrid';

export function Messages() {
  return (
    <>
      <Helmet>
        <title>Messages</title>
      </Helmet>
      <Grid container>
        <MessagesDataGrid />
      </Grid>
    </>
  );
}
