import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, matchPath, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { useSnackBar } from '../../../context/snackBarContext';
import {
  Column,
  DataGrid,
  Editing,
  Scrolling,
  StateStoring,
  Button,
  Format,
  Selection,
  Paging,
} from 'devextreme-react/data-grid';
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  Typography,
  Button as MuiButton,
} from '@mui/material';
import { ROUTE_JOBS_STUD_RAIL_QUANTITIES } from '../../../routes';

export default function CutsGrid({
  cutsGridRef,
  data: loadData,
  jobName,
  pagePermissions,
  refresh,
  setAccPickBtnState,
  setHasEditData,
}) {
  const { jobId } = useParams();
  const { pathname } = useLocation();
  const { setSnackbar } = useSnackBar();
  const [cuts, setCuts] = useState();
  const [loading, setLoading] = useState(true);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [cutToDelete, setCutToDelete] = useState(null);
  const isStudRailImport = !!matchPath(`/pt/jobs/${ROUTE_JOBS_STUD_RAIL_QUANTITIES}`, decodeURIComponent(pathname));

  // * get all the cuts for loadId
  useEffect(() => {
    if (pathname.length) {
      (async () => {
        setLoading(true);

        try {
          const response = await axios.get(`jobs/${jobId}/loads/${loadData.key}/cuts`, { loaderRequired: false });
          setCuts(response.data.value);
        } catch (error) {
          setSnackbar({
            open: true,
            message: 'Error getting Cuts data',
            severity: 'error',
          });
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [loadData.key, jobId, setSnackbar, pathname]);

  //* row count generate
  function rowCountGenerate(data) {
    return <span>{data.rowIndex + 1}</span>;
  }

  //* Cut column render method.
  const editCellRender = useCallback(
    (rowProps) => {
      const { data } = rowProps;
      const isAccessoriesCut = /^AC\d+/.test(data.cutNo);
      const isHardWareCut = /^HD\d+/.test(data.cutNo);
      const isBcAddOn = /^BC\d+/.test(data.cutNo);
      const isSC = /^SC\d+/.test(data.cutNo);

      if (isAccessoriesCut || isBcAddOn || isSC) return <span>{data.cutNo}</span>;

      return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
          {isAccessoriesCut || isBcAddOn || isHardWareCut ? (
            <span>{data.cutNo} </span>
          ) : (
            <Link
              underline="hover"
              component={RouterLink}
              state={{ jobName: jobName, selectedCut: data?.cutId }}
              to={`${encodeURIComponent(loadData.data?.description)}/cut-import?loadId=${data.loadId}&mode=edit`}
            >
              {data?.cutNo}
            </Link>
          )}
        </Box>
      );
    },
    [jobName]
  );

  async function updateCutStatus(cut, statusId) {
    await axios.put(`PT/Loads/${cut.loadId}/Cuts/${cut.cutId}/release?statusId=${statusId}`);
  }

  // function to handle the cut status change. 'Draft' & 'Release to shop'
  async function handleCutStatusChange(e, data) {
    const checkedVal = e.target.checked ? 2 : 1;

    if (!cuts?.length) return;

    try {
      await updateCutStatus(data, checkedVal);
      setSnackbar({ message: `Cut has been released successfully.`, open: true, severity: 'success' });

      await refresh();
    } catch (error) {
      setSnackbar({
        message: `Could not release the cut. Please check error message on top.`,
        open: true,
        severity: 'error',
      });
    }
  }

  //* Status Column render method.
  const statusColumnTemplate = ({ data, value, ...e }) => {
    return (
      <Checkbox
        size="medium"
        checked={value >= 2}
        disabled={value > 2 || !pagePermissions.canEdit} //when > release to shop
        onChange={(e) => handleCutStatusChange(e, data)}
      />
    );
  };

  //* Delete Icon visible render method - Based on 'Design' property.
  const deleteButtonVisible = useCallback((rowProps) => {
    return rowProps.row.data?.statusId === 1;
  }, []);

  async function handleDelete(cutId) {
    try {
      // data.key is load id.
      await axios.delete(`jobs/${jobId}/loads/${loadData.key}/cuts/${cutId}`);
      setSnackbar({ open: true, message: 'Cut deleted successfully', severity: 'success' });
      // handle the delete button state on the parent grid.
      const updateCuts = cuts?.filter((items) => items.cutId !== cutId);
      setCuts(updateCuts);
      if (!updateCuts?.length) await refresh();
    } catch (error) {
      setSnackbar({ open: true, message: 'Error in deleting cut', severity: 'error' });
    }
  }

  function onSaving(e) {
    e.cancel = true;
    // if no changes no api call.
    if (!e.changes?.length) {
      e.component?.cancelEditData();
      return;
    }
    const allRecords = cutsGridRef.current.instance.getVisibleRows();
    const cuts = [];
    e.changes.forEach((change) => {
      const row = allRecords.find((r) => r.data?.cutId === change.key);
      row?.data && cuts.push({ ...row.data, ...change.data });
    });

    e.promise = processBatchRequest(cuts, e.component);
  }

  async function processBatchRequest(payload, component) {
    try {
      await axios.post(`jobs/${jobId}/loads/${loadData.key}/cuts`, payload);
    } catch (error) {
      setSnackbar({
        message: `Error occured while saving cuts.`,
        severity: 'error',
        open: false,
      });
    } finally {
      component?.cancelEditData();
    }
  }

  //* on row selection set the Acc Pick button state in loadsForm.
  const onSelectionChanged = useCallback(
    (event) => {
      if (isStudRailImport) return;

      const cut = event.selectedRowsData[0];
      if (cut)
        setAccPickBtnState({
          isDisable: false,
          cutId: cut.cutId,
          loadId: cut.loadId,
          cutNo: cut.cutNo,
          cutBidItemId: cut.bidItemId,
        });
      else setAccPickBtnState({ isDisabled: true, cutId: null, loadId: null, cutNo: null });
    },
    [isStudRailImport, setAccPickBtnState]
  );

  function onEditorPreparing(e) {
    const isAccessoriesCut = /^AC\d+/.test(e.row.data.cutNo);
    const isBcAddOn = /^BC\d+/.test(e.row.data.cutNo);

    if (e.dataField === 'description') {
      // disabled for cable and for status id > 1
      e.editorOptions.disabled = !(isAccessoriesCut || isBcAddOn) || e.row.data.statusId > 1;
    }
  }

  function onContentReady(e) {
    if (isStudRailImport) return;
    // if prev is true -> i.e from load edit state. keep true.
    setHasEditData((prev) => prev || e.component.hasEditData());
  }

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <CircularProgress size={30} sx={{ color: '#3862ae' }} />
      </Box>
    );
  }

  return (
    <>
      <DataGrid
        ref={cutsGridRef}
        dataSource={cuts}
        showBorders={true}
        keyExpr={'cutId'}
        columnAutoWidth={true}
        rowAlternationEnabled={true}
        className="grid-cuts"
        onSaving={onSaving}
        onToolbarPreparing={(e) => e.toolbarOptions.items.splice(0)}
        onSelectionChanged={onSelectionChanged}
        onContentReady={onContentReady}
        onEditorPreparing={onEditorPreparing}
        allowColumnReordering={true}
        allowColumnResizing={true}
      >

        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey={isStudRailImport ? 'studs-cuts-data-grid' : 'cuts-data-grid'}
        />
        <Selection mode="single" />
        <Scrolling mode="standard" />
        {/* disabled for stud rails  */}
        <Editing mode="batch" allowUpdating={pagePermissions.canEdit && !isStudRailImport} />
        <Paging enabled={false} />

        <Column caption="#" alignment="center" cellRender={rowCountGenerate} width={50} allowEditing={false} />
        <Column dataField={'cutNo'} caption={'Cut'} cellRender={editCellRender} allowEditing={false} />
        <Column dataField={'description'} caption={'Description'} allowEditing />
        {isStudRailImport ? (
          <Column dataField={'totalStuds'} caption={'Total Studs'} alignment="center" allowEditing={false}>
            <Format type="fixedPoint" />
          </Column>
        ) : (
          <Column dataField={'linearLF'} caption={'Linear FT'} alignment="center" allowEditing={false}>
            <Format type="fixedPoint" />
          </Column>
        )}
        {isStudRailImport && (
          <Column
            dataField="totalChairs"
            caption={'Total Chairs'}
            alignment="center"
            dataType="string"
            allowEditing={false}
          />
        )}
        <Column dataField={'ctrlCode'} caption={'Ctrl Code'} allowEditing={false} />
        <Column dataField={'cutStatus'} caption={'Cut Status'} allowEditing={false} />
        {isStudRailImport && (
          <Column
            dataField="shippingMethod"
            caption={'Shipping Method'}
            alignment="center"
            dataType="string"
            allowEditing={false}
          />
        )}
        <Column
          dataField="statusId"
          caption={'Released to Shop'}
          dataType="boolean"
          cellRender={statusColumnTemplate}
          allowEditing={false}
        />

        <Column type="buttons">
          <Button
            name="delete"
            disabled={!pagePermissions.canEdit}
            visible={deleteButtonVisible}
            onClick={async (e) => {
              setCutToDelete(e.row.data.cutId);
              setOpenDeletePopup(true);
            }}
          />
        </Column>
      </DataGrid>

      <Dialog open={openDeletePopup} onClose={() => setOpenDeletePopup(false)}>
        <DialogContent>
          <Typography variant="h6">Are you sure you want to delete this record?</Typography>
        </DialogContent>
        <DialogActions>
          <MuiButton
            color="primary"
            onClick={async () => {
              await handleDelete(cutToDelete);
              setOpenDeletePopup(false);
            }}
            sx={{ px: 4, border: 'solid 1px', width: '100px' }}
          >
            Yes
          </MuiButton>
          <MuiButton color="error" onClick={() => setOpenDeletePopup(false)} sx={{ px: 4, border: 'solid 1px' }}>
            No
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
