import { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { env } from '../../env';
import {
  Autocomplete,
  createFilterOptions,
  Button,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  debounce,
  Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useUserContext } from '../../context/userContext';
import { AREA_NAMES } from '../../common/constants';
import { useRecentJobs } from '../../helper/recentJobsHelper';

export default function SearchBar() {
  const { user } = useUserContext();
  const { pathname } = useLocation();
  let isPTPage = false;
  if (pathname.toUpperCase().indexOf('/PT') >= 0) isPTPage = true;

  const pagePermissions = {
    canCreatePTJob: user.isCAM,
  };
  const mainInfoAccess = user?.isCAM || user?.isPTEngineer || user.isPTScheduler || user.isPTDispatcher || user.isAdmin;
  const loadsPageAccess = user?.isPTProjectManager || user?.isPTDetailer;

  const [dataList, setDataList] = useState([]);
  const { recentJobs: recentDataList, saveRecentJobs } = useRecentJobs(isPTPage ? AREA_NAMES.pt : AREA_NAMES.rebar);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(open && dataList.length);
  const [searchText, setSearchText] = useState('');
  const searchInput = useRef(null);

  const navigate = useNavigate();

  const filterOptions = createFilterOptions({
    stringify: ({ projectId, name, customerName }) => `${projectId} ${name} ${customerName}`,
  });

  useEffect(() => {
    if (!open) {
      setDataList([]);
    }
  }, [open]);

  const loadData = async (inputValue, setOptions) => {
    if (inputValue === undefined) {
      setDataList([]);
      return;
    }
    setLoading(true);
    let domain = isPTPage ? 'PT' : 'Rebar';
    if (inputValue) {
      axios
        .get(`Common/searchjobs?inputSearch=${inputValue}&domain=${domain}`, { loaderRequired: false })
        .then((response) => {
          if (response.data) {
            const filteredOptions = response.data.filter(
              (f) =>
                f.projectId.includes(inputValue) ||
                f.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
                f.customerName.toLowerCase().trim().includes(inputValue.toLowerCase().trim())
            );
            setDataList(filteredOptions);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log('Error occured while retrieving Job#.', error);
          setLoading(false);
        });
    }
  };

  const debouncedLoadData = debounce(loadData, 1000);

  const handleInputChange = async (value) => {
    debouncedLoadData(value?.trim(), setDataList);
  };

  const handleNavigate = async (value, isExternal) => {
    if (!value) return;
    // cached data of last visited project by user.
    // update the save recent jobs
    saveRecentJobs(value);

    if (isExternal) {
      if (isPTPage) window.open(`/PT/Jobs/${value.projectId}/MainInfo`, '_blank');
      else window.open(env.REACT_APP_CLASSIC_HOME + `project/${value.projectId}/jobinfo`, '_blank');
      setSearchText('');
      return;
    }

    if (isPTPage) {
      if (mainInfoAccess || (mainInfoAccess && loadsPageAccess)) {
        //  user has roles in both categories, navigate main Info
        navigate(`/PT/Jobs/${value.projectId}/MainInfo`);
      } else if (loadsPageAccess) {
        // default to Loads/PT Quantity for roles
        navigate(`/PT/Jobs/${value.projectId}/pt-quantities`);
      } else {
        navigate(`/PT/Jobs/${value.projectId}/MainInfo`);
      }
    } else {
      window.location.href = env.REACT_APP_CLASSIC_HOME + `project/${value.projectId}/jobinfo`;
    }
    setSearchText('');
  };

  function addNewPTJob() {
    navigate('/PT/NewJob');
  }

  function addNewFABJob() {
    navigate('/FAB/NewJob');
  }

  // When the search bar is clicked we are showing the recent jobs for ease of use
  function showRecentJobs() {
    setDataList(recentDataList);
  }

  function paperComponent(props) {
    return (
      <Paper
        sx={{
          backgroundColor: 'rgba(5, 38, 104, .9)',
          color: '#fff',
        }}
        {...props}
      />
    );
  }

  return (
    <Grid
      container
      spacing={0}
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        padding: { xs: '0 .5rem', sm: '0 3rem', md: '0' },
      }}
    >
      <Typography
        variant="h5"
        sx={{
          display: { xs: 'none', lg: 'block' },
          fontSize: { xs: '1.125rem', xl: '1.25rem' },
          fontWeight: 700,
          textAlign: 'right',
          textTransform: 'uppercase',
        }}
      >
        {isPTPage ? 'Post Tension' : 'Fabrication'}
      </Typography>
      <Grid
        item
        xs={9}
        md={6}
        xl={7}
        sx={{
          flexBasis: { xs: 'auto', md: '75%' },
          flexGrow: { xs: '1', md: '0' },
          padding: { xs: '0 1rem 0 0', md: '0 1rem', xl: '0 1.5rem' },
        }}
      >
        <Autocomplete
          ref={searchInput}
          value={searchText}
          open={open}
          onOpen={() => {
            showRecentJobs();
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onChange={(e, newValue) => {
            if (e.target instanceof SVGElement) {
              handleNavigate(newValue || null, true);
              return;
            }

            handleNavigate(newValue || null, false);
          }}
          filterOptions={filterOptions}
          loading={loading}
          //  noOptionsText={<span sx={{color: '#fff'}}> No options</span>}
          options={!dataList ? [{ label: 'Loading...', projectId: '0' }] : dataList}
          getOptionLabel={(option) => option?.projectId || option?.name || option?.customerName || ''}
          PaperComponent={paperComponent}
          renderOption={(props, option, state) => (
            <Box {...props} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '100%' }}>
              <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Box>
                  <Typography
                    sx={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }}
                  >{`${option.projectId} (${option.name})`}</Typography>
                  <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '12px', width: '100%' }}>
                    {option.customerName}
                  </Typography>
                </Box>
                <OpenInNewIcon sx={{ color: '#fff' }} />
              </Box>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search here using either the Job #  Job Name or Customer"
              variant="outlined"
              fullWidth
              onChange={(ev) => {
                handleInputChange(ev.target.value);
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    border: '1px solid #3862ae',
                  },
                  '&:hover': {
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#3862ae',
                    },
                  },
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid #3862ae',
                },
                // '& .MuiAutocomplete-loading':{color:'red'},
                '&:active': {
                  boxShadow: 'none',
                  outline: 'none',
                },
                input: {
                  color: 'rgba(255, 255, 255, 1)',
                  fontWeight: '300',
                  '&:focus::placeholder': {
                    color: 'transparent',
                  },
                  height: '1rem',
                  padding: '16.5px 18px',
                },
                label: {
                  color: 'rgba(255, 255, 255, .55)',
                },
              }}
            />
          )}
        />
      </Grid>
      {isPTPage && !pagePermissions.canCreatePTJob ? (
        ''
      ) : (
        <Grid item md={3} lg={2}>
          <Button
            variant="contained"
            className="btn btn-icon"
            startIcon={<AddIcon />}
            sx={{
              backgroundColor: '#53b7e8',
              '&:hover': {
                backgroundColor: '#65c8ef',
              },
              borderRadius: '6px',
              height: '46px',
              lineHeight: '1em',
              padding: { xs: '8px 12px', xl: '8px 16px' },
            }}
            onClick={isPTPage ? addNewPTJob : addNewFABJob}
          >
            <Stack direction="row" sx={{ marginRight: '4px' }}>
              {'Create'}
            </Stack>
            <Stack direction="row">{'New'}</Stack>
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
