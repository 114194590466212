import Logo from './logo';
import { Badge, Grid, Skeleton } from '@mui/material';
import SearchBar from './searchBar';
import ProfileNav from './profileNav';
import MessageIcon from '@mui/icons-material/Message';
import { useMessagesUnreadCount } from '../../utils/masterData';
import { useNavigate } from 'react-router-dom';

export default function Header({ nosearch }) {
  const { data: unreadMessagesCount, isLoading: unreadMessagesCountLoading } = useMessagesUnreadCount();
  const navigate = useNavigate();

  return (
    <div className="hdr">
      <Grid className="hdr-main" container rowSpacing={{ xs: 1, md: 0 }} columnSpacing={{ xs: 0 }}>
        <Grid
          item
          xs={12}
          md={3}
          lg={2}
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', md: 'flex-start' },
          }}
          className="hdr-col"
        >
          <Logo />
        </Grid>
        {!nosearch && (
          <Grid item xs={12} md={5} lg={6} className="hdr-col">
            <SearchBar />
          </Grid>
        )}
        <Grid item sm={4} lg={3} className="hdr-col col-links" sx={{ gap: 4 }}>
          {unreadMessagesCountLoading ? (
            <Skeleton
              variant="rounded"
              height={'.8em'}
              width={'2.2em'}
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.15)',
                boxShadow: '0 0 0 1px rgba(255, 255, 255, 0.15) inset',
              }}
            />
          ) : (
            <Badge
              badgeContent={unreadMessagesCount}
              color="primary"
              sx={{ cursor: 'pointer' }}
              onClick={() => navigate('/messages')}
            >
              <MessageIcon />
            </Badge>
          )}
          <ProfileNav />
        </Grid>
      </Grid>
    </div>
  );
}
