import Module from './module';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import GridGoldenratioIcon from '@mui/icons-material/GridGoldenratio';
import StartOutlinedIcon from '@mui/icons-material/StartOutlined';
import PodcastsOutlinedIcon from '@mui/icons-material/PodcastsOutlined';
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';
import NextPlanOutlinedIcon from '@mui/icons-material/NextPlanOutlined';
import ContentPasteGoOutlinedIcon from '@mui/icons-material/ContentPasteGoOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { env } from '../../env';
import { useUserContext } from '../../context/userContext';
import { useApprovalCount, useMessagesUnreadCount } from '../../utils/masterData';
import Badge from '@mui/material/Badge';
import MessageIcon from '@mui/icons-material/Message';

export default function ModuleList() {
  const { user: currentUser, loading } = useUserContext();
  const { data: approval } = useApprovalCount();
  const { data: unreadMessagesCount } = useMessagesUnreadCount();

  return (
    <Box sx={{ height: 'auto', overflow: 'hidden', padding: '12px' }}>
      <Grid container columnSpacing={1} rowSpacing={1}>
        {loading ? (
          Array.from({ length: 20 }, (index) => (
            <Module key={index}>
              <Skeleton width={'100%'} height={400} />
            </Module>
          ))
        ) : (
          <>
            {currentUser?.isSiteAdmin && (
              <Module target={env.REACT_APP_CLASSIC_HOME + 'Admin'}>
                <AccountBoxOutlinedIcon className="module-icon" />
                <Typography variant="button" className="module-title">
                  Admin
                </Typography>
              </Module>
            )}
            {currentUser?.postTension && (
              <Module target={`/PT`}>
                <GridGoldenratioIcon className="module-icon" />
                <Typography variant="button" className="module-title">
                  Post Tension
                </Typography>
              </Module>
            )}
            <Module target={env.REACT_APP_CLASSIC_HOME + 'Reports'}>
              <ArticleOutlinedIcon className="module-icon" />
              <Typography variant="button" className="module-title">
                Reports
              </Typography>
            </Module>
            {currentUser?.dispatch && (
              <Module target={env.REACT_APP_CLASSIC_HOME + 'Dispatch'}>
                <StartOutlinedIcon className="module-icon" />
                <Typography variant="button" className="module-title">
                  Dispatch
                </Typography>
              </Module>
            )}
            {currentUser?.dispatch && (
              <Module target={env.REACT_APP_CLASSIC_HOME + 'Broadcast'}>
                <PodcastsOutlinedIcon className="module-icon" />
                <Typography variant="button" className="module-title">
                  Broadcast
                </Typography>
              </Module>
            )}
            {currentUser?.timeManagement && (
              <Module target={env.REACT_APP_CLASSIC_HOME + 'Timemanagement'}>
                <EditCalendarOutlinedIcon className="module-icon" />
                <Typography variant="button" className="module-title">
                  Time Mgmt.
                </Typography>
              </Module>
            )}
            {currentUser?.coc && (
              <Module target={env.REACT_APP_CLASSIC_HOME + 'COC'}>
                <NextPlanOutlinedIcon className="module-icon" />
                <Typography variant="button" className="module-title">
                  COC
                </Typography>
              </Module>
            )}
            <Module target={env.REACT_APP_CLASSIC_HOME + 'Streamline/ProjectedReleases'}>
              <ContentPasteGoOutlinedIcon className="module-icon" />
              <Typography variant="button" className="module-title">
                Releases
              </Typography>
            </Module>
            {currentUser?.approvalAmount > 0 && (
              <Module target={env.REACT_APP_CLASSIC_HOME + 'Approvals/Approval'}>
                <Badge badgeContent={!approval ? '0' : approval.toString()} color="primary">
                  <TaskAltIcon className="module-icon" />
                </Badge>
                <Typography variant="button" className="module-title">
                  Approvals
                </Typography>
              </Module>
            )}
            <Module target={env.REACT_APP_CLASSIC_HOME + 'Streamline/ScheduledSubmittals'}>
              <PendingActionsOutlinedIcon className="module-icon" />
              <Typography variant="button" className="module-title">
                Sched. Submittals
              </Typography>
            </Module>
            <Module target={'messages'}>
              <Badge badgeContent={unreadMessagesCount} color="primary">
                <MessageIcon className="module-icon" />
              </Badge>
              <Typography variant="button" className="module-title">
                Messages
              </Typography>
            </Module>
          </>
        )}
      </Grid>
    </Box>
  );
}
