import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { env } from '../../env';
import { Box, Card, CardHeader, List, ListItem, ListItemText, ListItemButton } from '@mui/material';
import { AREA_NAMES } from '../../common/constants';
import { useRecentJobs } from '../../helper/recentJobsHelper';
import { useUserContext } from '../../context/userContext';

export default function RecentJobs() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user } = useUserContext();

  let isPTPage = !matchPath(``, decodeURIComponent(pathname));

  const { recentJobs: recentProjects, saveRecentJobs } = useRecentJobs(isPTPage ? AREA_NAMES.pt : AREA_NAMES.rebar);
  const mainInfoAccess = user?.isCAM || user?.isPTEngineer || user.isPTScheduler || user.isPTDispatcher || user.isAdmin;
  const loadsPageAccess = user?.isPTProjectManager || user?.isPTDetailer;

  function onRecentItemClick(data) {
    saveRecentJobs(data);
    if (data.area === AREA_NAMES.pt) {
      if (mainInfoAccess || (mainInfoAccess && loadsPageAccess)) {
        //  user has roles in both categories, navigate main Info
        navigate(`/PT/Jobs/${data.projectId}/MainInfo`);
      } else if (loadsPageAccess) {
        // default to Loads/PT Quantity for roles
        navigate(`/PT/Jobs/${data.projectId}/pt-quantities`);
      }
    } else window.location.href = env.REACT_APP_CLASSIC_HOME + `project/${data.projectId}/jobinfo`;
  }

  return (
    <Card className="card">
      <CardHeader className="card-hdr" title="Recent Jobs" />
      {recentProjects.length === 0 ? (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            fontSize: '1rem',
            height: 'calc(100% - 90px)',
            justifyContent: 'center',
          }}
        >
          No recent jobs found.
        </Box>
      ) : (
        <List disablePadding sx={{ height: 'auto', maxHeight: '506px', overflowY: 'auto' }}>
          {recentProjects?.map((ace, i) => (
            <ListItem disableGutters disablePadding divider key={ace.projectId}>
              <ListItemButton
                onClick={() => onRecentItemClick(ace)}
                sx={{
                  backgroundColor: i % 2 === 0 && '#f3f3f3',
                  padding: '.3rem 1.5rem',
                  '&:hover': {
                    backgroundColor: '#dbe7fd',
                  },
                  '&& .MuiTouchRipple-child': {
                    backgroundColor: '#3862ae',
                  },
                }}
              >
                <ListItemText sx={{ textAlign: 'left', width: '230px' }} primary={`${ace.projectId} - ${ace.name}`} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </Card>
  );
}
