import { useRef, useState, useCallback, useEffect } from 'react';
import { Grid, Box, Button, Typography } from '@mui/material';
import { DataGrid, Column, Editing, Scrolling, Lookup, CustomRule } from 'devextreme-react/data-grid';
import ActionBar from '../../shared/actionBar';
import { ActionSubmitButton2 } from '../../shared/actionSubmitButton';
import ActionCancelButton from '../../shared/actionCancelButton';
import AddIcon from '@mui/icons-material/Add';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useSnackBar } from '../../../context/snackBarContext';

const PTIFilingForm = () => {
  const ptiFillingGridRef = useRef();

  const [millHeatInfoFilling, setMillHeatInfoFilling] = useState(null);
  const { jobId } = useParams();
  const [hasEditData, setHasEditData] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const [searchParams] = useSearchParams();
  const { setSnackbar } = useSnackBar();

  const loadId = searchParams.get('loadId');
  const { data: cutsData } = useQuery({
    queryKey: [loadId],
    queryFn: async () => (await axios.get(`jobs/${jobId}/Loads/${loadId}/Cuts`, { loaderRequired: false })).data,
    refetchOnMount: 'always',
    refetchOnWindowFocus: 'always',
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`jobs/${jobId}/Loads/${loadId}/PTI`);
        setMillHeatInfoFilling(response.data.value);
      } catch (error) {}
    })();
  }, []);

  function handleSaveMillHeatInfo() {
    ptiFillingGridRef.current.instance.saveEditData();
    setHasEditData(true);
  }
  function handleOnContentReady(e) {
    setHasEditData(e.component.hasEditData());
  }
  const handleOnToolbarPreparing = useCallback((e) => {
    e.toolbarOptions.items.splice(0);
  }, []);

  async function processMillHeatInfoSaveRequest(payload, component) {
    try {
      setIsSubmiting(true);

      const saveResponse = await axios.put(`jobs/${jobId}/Loads/${loadId}/PTI`, payload, { loaderRequired: false });

      let snackbar = { message: 'Saved successfully.', isAction: false, severity: 'success' };
      if (saveResponse.data?.errors?.length) {
        snackbar.isAction = true;
        snackbar.message = (
          <Typography>
            {`Mill Heat Information Saved Successfully. Could not locate the MTR files for the following heat numbers: ${saveResponse.data.errors.join(
              ', '
            )}. Please update them before printing PTI Filing report`}
          </Typography>
        );
        snackbar.severity = 'info';
      }

      const response = await axios.get(`jobs/${jobId}/Loads/${loadId}/PTI`);
      setMillHeatInfoFilling(response.data.value);
      setHasEditData(true);

      setSnackbar({ open: true, ...snackbar });
    } catch (error) {
      setSnackbar({ open: true, message: 'Error saving.', severity: 'error' });
    } finally {
      setIsSubmiting(false);
      component.cancelEditData();
    }
  }

  const handleMillHeatInfoSaving = useCallback(
    (event) => {
      event.cancel = true;
      let payload = {
        millHeatInfo: [],
        millHeatInfoToRemove: [],
      };

      //* generate the payload
      if (event.changes.length) {
        let allRecords = ptiFillingGridRef.current.instance.getVisibleRows();

        event.changes.forEach((change) => {
          if (change.type === 'remove') payload.millHeatInfoToRemove.push({ heatLinkId: change.key });
          else if (change.type === 'insert') {
            payload.millHeatInfo.push(change.data);
          } else {
            const row = allRecords.find((r) => r.data.heatLinkId === change.key);
            payload.millHeatInfo.push({ ...row.data });
          }
        });

        //* handle async request
        event.promise = processMillHeatInfoSaveRequest(payload, event.component);
      }
    },
    [processMillHeatInfoSaveRequest]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Button
            onClick={() => ptiFillingGridRef.current.instance.addRow()}
            className="btn btn-blue btn-icon"
            startIcon={<AddIcon />}
          >
            Add
          </Button>
        </Box>
      </Grid>
      <Grid item>
        <DataGrid
          height={440}
          showBorders={true}
          ref={ptiFillingGridRef}
          keyExpr={'heatLinkId'}
          dataSource={millHeatInfoFilling}
          onToolbarPreparing={handleOnToolbarPreparing}
          onContentReady={handleOnContentReady}
          onSaving={handleMillHeatInfoSaving}
        >
          <Editing allowAdding allowUpdating allowDeleting mode="batch" /> <Scrolling mode="infinite" />
          <Column dataField="cutId" caption="Cut #" allowFiltering={false} alignment="left">
            <Lookup dataSource={cutsData?.value} displayExpr="cutNo" valueExpr="cutId" />
          </Column>
          <Column
            dataField="cableHeat"
            editorOptions={{ maxLength: 20 }}
            caption="CABLE HEAT #"
            allowFiltering={false}
            alignment="left"
          ></Column>
          <Column dataField="cableVendor" caption="Cable Vendor" allowFiltering={false} alignment="left">
            <CustomRule
              // if cable heat value is there. this field is required.
              validationCallback={(props) => !(props.data?.cableHeat?.length && !props.value?.length)}
              message="Cable Vendor is required"
            />
          </Column>
          <Column
            dataField="coilNumber"
            editorOptions={{ maxLength: 20 }}
            caption="Coil Number"
            allowFiltering={false}
            alignment="left"
          ></Column>
          <Column
            dataField="deAnchors"
            editorOptions={{ maxLength: 20 }}
            caption="D.E Anchors"
            allowFiltering={false}
            alignment="left"
          ></Column>
          <Column
            dataField="deWedges"
            editorOptions={{ maxLength: 20 }}
            caption="D.E Wedges"
            allowFiltering={false}
            alignment="left"
          ></Column>
          <Column
            dataField="leAnchors"
            editorOptions={{ maxLength: 20 }}
            caption="L.E Anchors"
            allowFiltering={false}
            alignment="left"
          ></Column>
          <Column
            dataField="leWedges"
            editorOptions={{ maxLength: 20 }}
            caption="L.E Wedges"
            allowFiltering={false}
            alignment="left"
          ></Column>
          <Column
            dataField="deCouplers"
            editorOptions={{ maxLength: 20 }}
            caption="D.E Couplers"
            allowFiltering={false}
            alignment="left"
          ></Column>
          <Column
            dataField="intAnchors"
            editorOptions={{ maxLength: 20 }}
            caption="INT Anchors"
            allowFiltering={false}
            alignment="left"
          ></Column>
        </DataGrid>
      </Grid>
      <ActionBar sx={{ justifyContent: 'end', display: 'flex', gap: '10px' }}>
        <ActionSubmitButton2
          loading={isSubmiting}
          buttonText={'Save'}
          onClick={handleSaveMillHeatInfo}
          disabled={!hasEditData || isSubmiting}
        />
        <ActionCancelButton
          buttonText={'cancel'}
          clickHandler={() => ptiFillingGridRef.current.instance.cancelEditData()}
          disabled={isSubmiting}
        />
      </ActionBar>
    </Grid>
  );
};
export default PTIFilingForm;
