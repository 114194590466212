import saveAs from 'file-saver';
import { useCallback, useEffect, useRef, useState } from 'react';
import ActionBar from '../../shared/actionBar';
import { ActionSubmitButton2 } from '../../shared/actionSubmitButton';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import ActionCancelButton from '../../shared/actionCancelButton';
import {
  Column,
  CustomRule,
  Editing,
  DataGrid,
  Scrolling,
  Selection,
  Lookup,
  StateStoring,
  SearchPanel,
  Format,
  Sorting,
  HeaderFilter,
} from 'devextreme-react/data-grid';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  ListItem,
  ListItemText,
  Card,
  CardContent,
} from '@mui/material';
import axios from 'axios';
import GridSkeleton from '../../shared/gridSkeleton';
import { useCutStatus, useDeliveryTypes, useAdditionalItems } from '../../../utils/masterData';
import { useSnackBar } from '../../../context/snackBarContext';
import { useNavigate } from 'react-router-dom';
import { queryClient } from '../../../App';
import { convertToGMT } from '../../../utils/dateUtils';
import moment from 'moment/moment';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { MultiSelect } from '../../shared/multiSelect';
import { useQuery } from '@tanstack/react-query';
import { useSchedulesContext } from '../../../context/schedulesContext';
import { CUT_STAUTS, LOAD_TYPE, PRODUCTION_STATUS, DELIVERY_TYPES } from '../../../common/constants';
import { DropDownOptions } from 'devextreme-react/lookup';
import { CustomLookupDropdown } from '../../shared/dataGrid/customLookupDropdown';

export default function DispatchSchedule({ pagePermissions }) {
  const dispatchScheduleDataGridRef = useRef(null);
  const observerRef = useRef(null);
  const { selectedLocations, PTLocations, setSelectedLocations } = useSchedulesContext();
  const { setSnackbar } = useSnackBar();
  const navigate = useNavigate();
  const { data: deliveryTypesLookup } = useDeliveryTypes();
  const { data: cutStatusLookup } = useCutStatus();
  const { data: additionalItemsLookup } = useAdditionalItems();
  const [hasEditData, setHasEditData] = useState(false);
  const [selectedLoad, setSelectedLoad] = useState([]);
  const [alertDialogContent, setAlertDialogContent] = useState({ contnet: '' });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isSapExportDisabled, setIsSapExportDisabled] = useState(true);
  const [sapExportDialogOpen, setSapExportDialogOpen] = useState(false);
  const [isSapExporting, setIsSapExporting] = useState(false);

  // * get all the dispatch schedule data
  const {
    data: dispatchSchedules,
    isLoading,
    error,
  } = useQuery({
    queryKey: [
      `pt/Schedules`,
      {
        status: [
          PRODUCTION_STATUS.dateApproved,
          PRODUCTION_STATUS.tagged,
          PRODUCTION_STATUS.sentToTMW,
          PRODUCTION_STATUS.WIP,
          PRODUCTION_STATUS.inProduction,
        ],
        locations: selectedLocations.map((l) => l.locationID),
      },
    ],
    queryFn: async () => {
      const response = await axios.get('pt/Schedules', {
        loaderRequired: false,
        params: {
          status: [
            PRODUCTION_STATUS.dateApproved,
            PRODUCTION_STATUS.tagged,
            PRODUCTION_STATUS.sentToTMW,
            PRODUCTION_STATUS.WIP,
            PRODUCTION_STATUS.inProduction,
          ],
          locations: selectedLocations.map((l) => l.locationID),
        },
        paramsSerializer: { indexes: null },
      });
      if (response.data?.loads) {
        response.data?.loads.forEach((load) => {
          load.displayDeliveryTime = Date.parse('01/01/1900 ' + load.deliveryTime);
        });
      }
      return response.data?.loads;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: 0,
  });

  useEffect(() => {
    localStorage.setItem('pt-location-filter', JSON.stringify(selectedLocations));
  }, [selectedLocations]);

  useEffect(() => {
    return () => {
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, []);

  //* handle edit btn state
  function handleOnContentReady(e) {
    setHasEditData(e.component.hasEditData());

    // the fixed header class
    const header = e.element.querySelector('.dx-datagrid-headers');

    if (header) {
      observerRef.current = new IntersectionObserver(
        (entry) => {
          if (entry[0].target) header.classList.toggle('sticky-datagrid-header', !entry[0].isIntersecting);
        },
        { rootMargin: '-130px 0px 0px 0px' }
      );

      observerRef.current.observe(header);
    }
  }

  //* save button handler
  function handleSaveButton() {
    dispatchScheduleDataGridRef.current.instance.saveEditData();
    setHasEditData(true);
  }

  async function updateDispatchSchedule(loads) {
    return axios.put('pt/schedules/dispatchSchedules', loads);
  }

  async function gridRefresh() {
    await queryClient.invalidateQueries({ queryKey: [`pt/Schedules`] });
  }

  //* Handle batch edit process request
  async function processBatchRequest(loadPayload, component) {
    try {
      await updateDispatchSchedule(loadPayload);
      setSnackbar({ open: true, message: 'Saved dispatch schedules successfully.', severity: 'success' });
      component.cancelEditData();
      await gridRefresh();
      component.clearSelection();
      setSelectedLoad([]);
    } catch (error) {
      setSnackbar({ open: true, message: 'Error saving dispatch schedules.', severity: 'error' });
    }
  }
  const renderLocationLookupDropdown = useCallback((props) => {
    const {
      data: { row },
    } = props;

    const disabled = row.data.deliverTo ? row.data.deliverTo !== DELIVERY_TYPES.stoYard : true;
    const contentRender = (data) => (
      <ListItem disablePadding style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
        <ListItemText secondaryTypographyProps={{ style: { fontWeight: 800 } }} secondary={`${data.locationName}`} />
        <ListItemText secondary={`${data.locationID}`} />
      </ListItem>
    );

    return (
      <CustomLookupDropdown
        searchEnabled
        searchExpr={['locationName', 'locationID']}
        displayExpr={'locationID'}
        valueExpr="locationID"
        itemRender={contentRender}
        showClearButton
        disabled={disabled}
        {...props}
      >
        <DropDownOptions
          minWidth={250}
          minHeight={400}
          position={{ collision: 'flip', at: 'top', of: `.deliveryPlant-${props.data.key}` }}
        />
      </CustomLookupDropdown>
    );
  }, []);

  //* on save data grid handler
  async function handleSaving(e) {
    e.cancel = true;
    let payload = { loads: [] };
    if (!e.changes.length) return;

    for (const change of e.changes) {
      const data = await dispatchScheduleDataGridRef.current.instance.byKey(change.key);
      if (!data) return;

      let item = {
        ...data,
        deliveryDate: data.deliveryDate ? convertToGMT(new Date(data.deliveryDate)) : null,
        productionDate: data.productionDate ? convertToGMT(new Date(data.productionDate)) : null,
        custReqDate: data.custReqDate ? convertToGMT(new Date(data.custReqDate)) : null,
      };

      // Converting delivery date to formatted text value of delivery time.
      // Note: it is displayDeliveryTime the dataSource, changes avaliable only on that.
      item.deliveryTime = moment(item.displayDeliveryTime).format('hh:mm A');

      payload.loads.push(item);
    }

    e.promise = processBatchRequest(payload, e.component);
  }

  const loadState = useCallback(() => {
    const state = localStorage.getItem('dispatch-schedules-grid');
    if (state) return JSON.parse(state);
    return null;
  }, []);

  // removing the sorting from other col's and keeping default sorting for columns
  const saveState = useCallback((state) => {
    const updateColumns = state.columns.map((column) => {
      if (column.dataField === 'displayDeliveryTime' || column.dataField === 'deliveryDate') {
        column.sortOrder = 'desc';
        column.sortIndex = 0;
      } else {
        delete column.sortIndex;
        delete column.sortOrder;
      }

      // removes columns filters values.
      if (column?.filterValues?.length) {
        delete column.filterValues;
      }
      return column;
    });
    if (state?.searchText) {
      delete state.searchText;
    }
    if (state?.selectedRowKeys?.length) {
      delete state.selectedRowKeys;
    }

    let updateState = { ...state, columns: updateColumns };
    localStorage.setItem('dispatch-schedules-grid', JSON.stringify(updateState));
  }, []);

  function editorOnValueChanged(args, event) {
    if (event.dataField !== 'productionStatusId') {
      event.setValue(args.value);
      return;
    }

    // on production value changes, handle the alert message.
    const prevValue = args.previousValue;
    const value = args.value;

    // if prev value >= sent to TMW and change value is < sent to TMW (inProduction value check also).
    if (
      prevValue >= PRODUCTION_STATUS.sentToTMW &&
      prevValue !== PRODUCTION_STATUS.inProduction &&
      (value < PRODUCTION_STATUS.sentToTMW || value === PRODUCTION_STATUS.inProduction)
    ) {
      // now changed to inProduction
      if (value === PRODUCTION_STATUS.inProduction) {
        setAlertDialogContent('Make sure to reverse the PGI and delete the delivery in SAP.');
        setDialogOpen(true);
      } else {
        setAlertDialogContent(
          `Make sure to reverse the PGI and delete the delivery in SAP. ${
            event.row.data.type === LOAD_TYPE.cable ? 'Tags also need to be reprinted' : ''
          }.`
        );
        setDialogOpen(true);
      }
      event.setValue(args.value);
      return;
    }

    // If the previous value is 'tagged' and the new value is below 'tagged'
    if (
      (prevValue === PRODUCTION_STATUS.tagged || prevValue === PRODUCTION_STATUS.inProduction) &&
      value < PRODUCTION_STATUS.tagged &&
      event.row.data.type === LOAD_TYPE.cable
    ) {
      setAlertDialogContent('Tags need to be reprinted.');
      setDialogOpen(true);
      event.setValue(args.value);
      return;
    }
    event.setValue(args.value);
  }

  function onEditorPreparing(event) {
    // Check if job is on hold
    if (event.row.data.jobOnHold) {
      event.editorOptions.readOnly = true;
      return;
    }

    if (event.dataField === 'productionStatusId') {
      event.editorOptions.dataSource = getFilteredStatuses(event);
    }

    event.editorOptions.onValueChanged = (args) => editorOnValueChanged(args, event);
  }

  //  filtered statuses
  function getFilteredStatuses(event) {
    return cutStatusLookup
      ?.filter((status) => status.id >= CUT_STAUTS.releaseToShop)
      .map((status) => {
        const rowProductionStatusId = getRowProductionStatusId(event);
        let isDisable = shouldDisableStatus(status, rowProductionStatusId, event);

        return { ...status, disabled: isDisable };
      });
  }

  //  row production status id
  function getRowProductionStatusId(event) {
    return event.row?.oldData ? event.row.oldData.productionStatusId : event.row.data.productionStatusId;
  }

  // if status should be disabled
  function shouldDisableStatus(status, rowProductionStatusId, event) {
    let isDisable = isHigherStatusThanCurrent(status, rowProductionStatusId);

    if (isTaggedOrSentToTMW(status)) {
      isDisable = true;
    }

    if (isInProductionAndEnableLower(status, rowProductionStatusId)) {
      isDisable = false;
    }

    if (isInProductionAndDisableHigher(status, rowProductionStatusId)) {
      isDisable = true;
    }

    if (enableInProduction(status, rowProductionStatusId, event)) {
      isDisable = false;
    }

    return isDisable;
  }

  // if status is a higher status than current
  function isHigherStatusThanCurrent(status, rowProductionStatusId) {
    return rowProductionStatusId + 1 < status.id;
  }

  //  if status is tagged or sent to TMW
  function isTaggedOrSentToTMW(status) {
    return status.id === PRODUCTION_STATUS.tagged || status.id === PRODUCTION_STATUS.sentToTMW;
  }

  //  if status is In-Production and should enable lower status
  function isInProductionAndEnableLower(status, rowProductionStatusId) {
    return rowProductionStatusId === CUT_STAUTS.inProduction && status.id <= CUT_STAUTS.dateApproved;
  }

  // if status is In-Production and should disable higher status
  function isInProductionAndDisableHigher(status, rowProductionStatusId) {
    return rowProductionStatusId === CUT_STAUTS.inProduction && status.id >= CUT_STAUTS.sentToTMW;
  }

  // if status should be enabled when row production status is tagged
  function enableInProduction(status, rowProductionStatusId, event) {
    return (
      (rowProductionStatusId === CUT_STAUTS.tagged ||
        (rowProductionStatusId === CUT_STAUTS.dateApproved && event.row.data.type === LOAD_TYPE.studRails) ||
        rowProductionStatusId >= CUT_STAUTS.sentToTMW) &&
      status.id === CUT_STAUTS.inProduction
    );
  }

  const deliveryDateValidate = (options) => {
    //* get all data to validate
    return moment(options.data.productionDate) <= moment(options.value);
  };

  const productionDateValidate = (options) => {
    return moment(options.data.deliveryDate) >= moment(options.value);
  };

  function handleShopPaperwork() {
    if (!selectedLoad[0].loadId) {
      setSnackbar({
        open: true,
        message: `Please select a load`,
        severity: 'error',
      });
      return;
    }

    navigate(
      `${selectedLoad[0].projectId}/${selectedLoad[0].description}/shop paperwork?loadId=${selectedLoad[0].loadId}&jobName=${selectedLoad[0].jobName}`
    );
  }
  function onRowPrepared(event) {
    if (event.rowType === 'data' && event.data.hasMultiStatusCuts) {
      event.rowElement.style.borderLeft = 'solid 5px red';
    }
    if (event.rowType === 'data' && event.data.isExternal) {
      event.rowElement.classList.add('is-external');
    }
  }

  function onCellPrepared(e) {
    // adding class name for deliveryPlants
    if (e.rowType === 'data' && e.column.dataField === 'deliveryPlant') {
      e.cellElement.classList.add('deliveryPlant-' + e.key);
    }
  }

  useEffect(() => {
    if (!selectedLoad?.length) setIsSapExportDisabled(true);
    else {
      let isExportDisabled = false; // flag to check for production status (initial state disabled).
      // set object to check for PTLocationId, all selected loads must contain same pt-location-id.
      const ptLocationIdSet = new Set(selectedLoad.map((item) => item.ptLocationId));

      selectedLoad.forEach((item) => {
        // checking if job is on Hold.
        if (item.jobOnHold) isExportDisabled = true;
        // when productionStatusId is not set to inProduction(8), then set productionStatusFlag=true to disable the export btn.
        // for both the load type.
        if (item.productionStatusId !== PRODUCTION_STATUS.inProduction) {
          isExportDisabled = true;
        }
      });
      // here set size should be one. that is single PT Location ID can be exported at a time.
      const exportDisable = isExportDisabled || ptLocationIdSet.size !== 1;
      setIsSapExportDisabled(exportDisable);
    }
  }, [selectedLoad, dispatchSchedules]);

  // export to xl
  const handleExportDataGrid = useCallback(() => {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Dispatch Schedules');
    exportDataGrid({
      component: dispatchScheduleDataGridRef.current.instance,
      worksheet: worksheet,
    })
      .then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'dispatch-schedules.xlsx');
        });
      })
      .catch(() => {
        setSnackbar({ message: 'Error in saving the xl file.', open: true, severity: 'error' });
      });
  }, [dispatchScheduleDataGridRef, exportDataGrid, saveAs]);

  async function handleExportToSAP() {
    if (!selectedLoad[0].loadId) {
      setSnackbar({
        open: true,
        message: `Please select a load`,
        severity: 'error',
      });
      return;
    }
    const loadIDs = selectedLoad.map((item) => item.loadId);

    try {
      setIsSapExporting(true);
      const res = await axios.post(`pt/Schedules/exportcuts`, loadIDs, { loaderRequired: false });
      setSnackbar({
        open: true,
        message: `${res?.data?.message}`,
        severity: 'success',
      });
      await gridRefresh();
      dispatchScheduleDataGridRef?.current?.instance.clearSelection();
    } catch (error) {
    } finally {
      setIsSapExporting(false);
      setSapExportDialogOpen(false);
    }
  }

  if (isLoading) return <GridSkeleton />;

  if (error) return <div> Error getting data </div>;

  // shop schedules/dispatch/shipped, # of Cuts/Rails
  const renderNumberOfCutsRailsCell = (rowData) => {
    const cutsRails = rowData.type === 2 ? rowData.totalRails : rowData.cutCount;
    return cutsRails;
  };

  // shop schedules/dispatch/shipped, total LF
  const renderLFCell = (rowData) => {
    const LFvalue = rowData.type === 2 ? rowData.totalLinealFootage : rowData.actualLF;
    return LFvalue;
  };

  const validateDeliveryPlant = (event) => {
    const deliverTo = event.data?.deliverTo;

    return !(deliverTo === 3 && !event.data?.deliveryPlant);
  };

  const setDeliveryPlantValue = function (newData, value, currentRowData) {
    newData.deliverTo = value;
    if (value !== 3 && currentRowData.deliveryPlant) {
      newData.deliveryPlant = null;
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={12}
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          margin: '18px 0 3px',
        }}
      >
        <Button
          onClick={() => navigate(`/pt/jobs/${selectedLoad[0].projectId}/contacts`)}
          disabled={selectedLoad.length !== 1}
          className="btn btn-blue"
        >
          Job site contacts
        </Button>
        <Typography variant="h1">Dispatch Schedule</Typography>
        <Box display={'flex'} alignItems={'center'} gap={2} id="search">
          <Button
            className="btn btn-blue"
            disabled={isSapExportDisabled || !pagePermissions.canEditDispatch}
            onClick={() => setSapExportDialogOpen(true)}
          >
            Export to SAP
          </Button>
          <Button className="btn btn-blue" disabled={selectedLoad.length !== 1} onClick={handleShopPaperwork}>
            Shop Paperwork
          </Button>
          <Button className="btn btn-blue" onClick={handleExportDataGrid}>
            Export to Excel
          </Button>
          <MultiSelect
            className="pt-location-filter"
            size="small"
            placeholder={'Filter by locations'}
            options={PTLocations}
            selected={selectedLocations}
            setSelected={setSelectedLocations}
            onChange={(e) => setSelectedLocations(e.target.value)}
            onDelete={(value) => {
              const updateSelected = selectedLocations.filter(
                (location) => location['locationID'] !== value['locationID']
              );
              setSelectedLocations(updateSelected);
            }}
            value={'locationID'}
            name={'locationName'}
            sx={{ maxWidth: 300, minWidth: 200 }}
          />
          <TextField
            id="search-grid"
            size="small"
            variant="filled"
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className="search-icon">
                  <SearchSharpIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              dispatchScheduleDataGridRef.current.instance.searchByText(e.target.value);
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} marginBottom={12}>
        <DataGrid
          ref={dispatchScheduleDataGridRef}
          id="gridContainer"
          dataSource={dispatchSchedules}
          keyExpr={'loadId'}
          showBorders={true}
          rowAlternationEnabled={true}
          onSaving={handleSaving}
          onToolbarPreparing={(e) => e.toolbarOptions.items.splice(0)}
          onContentReady={handleOnContentReady}
          allowColumnReordering={true}
          allowColumnResizing={true}
          onEditorPreparing={onEditorPreparing}
          onRowPrepared={onRowPrepared}
          onSelectionChanged={(e) => {
            setSelectedLoad(e.selectedRowsData);
          }}
          onCellPrepared={onCellPrepared}
        >
          <Selection mode={'multiple'} selectByClick showCheckBoxesMode="none" />
          <Editing
            allowUpdating={pagePermissions.canEditDispatch}
            allowEditing={pagePermissions.canEditDispatch}
            mode="batch"
          />
          <Scrolling mode="virtual" />
          <SearchPanel visible={false} placeholder="Search..." />
          <StateStoring type="custom" enabled={true} customSave={saveState} customLoad={loadState} />
          <Sorting mode="multiple" />
          <HeaderFilter visible={true} />

          <Column
            dataField={'productionDate'}
            allowFiltering={false}
            caption={'Production Date'}
            dataType="date"
            format={'MM/dd/yyyy'}
            allowHeaderFiltering={true}
          >
            <CustomRule
              ignoreEmptyValue={true}
              validationCallback={productionDateValidate}
              message="Production Date should be earlier than Delivery Date."
            />
          </Column>
          <Column
            dataField={'deliveryDate'}
            allowFiltering={false}
            caption={'Delivery Date'}
            dataType="date"
            format={'MM/dd/yyyy'}
            defaultSortOrder="desc"
            allowHeaderFiltering={true}
          >
            <CustomRule
              validationCallback={deliveryDateValidate}
              message="Delivery Date cannot be earlier than Production Date"
            />
          </Column>
          <Column
            dataField={'displayDeliveryTime'}
            allowFiltering={false}
            caption={'Delivery Time'}
            dataType="datetime"
            format="shortTime"
            pickerType="calendar"
            editorOptions={{ type: 'time' }}
            defaultSortOrder="desc"
            allowHeaderFiltering={false}
          />
          <Column
            dataField="state"
            caption="State"
            allowSearch
            allowFiltering={false}
            allowEditing={false}
            allowHeaderFiltering={false}
          />
          <Column
            dataField="projectId"
            caption="Job #"
            allowSearch
            allowEditing={false}
            allowFiltering={false}
            allowHeaderFiltering={true}
          />
          <Column
            dataField="jobName"
            caption="Job Name"
            dataType='"string'
            allowSearch
            allowEditing={false}
            allowFiltering={false}
            allowHeaderFiltering={false}
          />
          <Column
            dataField="description"
            caption="Load Description"
            allowSearch
            allowEditing={false}
            allowFiltering={false}
            allowHeaderFiltering={false}
          />
          <Column dataField="cutNumbers" allowEditing={false} caption="Cut #’s" allowHeaderFiltering={false} />
          <Column dataField={'ctrlCodes'} caption={'Ctrl Code'} allowEditing={false} allowHeaderFiltering={true} />
          <Column
            allowEditing={false}
            caption="LF of Cable/Rails"
            dataField="lf of Cable/Rails"
            allowHeaderFiltering={false}
            calculateCellValue={renderLFCell}
          ></Column>
          <Column
            allowEditing={false}
            caption="# of Cables/Rails"
            alignment="right"
            dataField="rails"
            allowHeaderFiltering={false}
            calculateCellValue={renderNumberOfCutsRailsCell}
            dataType="number"
          >
            <Format type="fixedPoint" />
          </Column>
          <Column
            dataField="deliveryPlant"
            caption="Delivery Plant"
            alignment="left"
            editCellComponent={(props) => renderLocationLookupDropdown(props)}
          >
            <Lookup dataSource={PTLocations} valueExpr="locationID" displayExpr="locationID" />
            <CustomRule message="Delivery Plant is required." validationCallback={validateDeliveryPlant} />
          </Column>
          <Column dataField="productionStatusId" caption="Production Status" allowHeaderFiltering={true}>
            <Lookup
              dataSource={cutStatusLookup?.filter((status) =>
                [
                  PRODUCTION_STATUS.releaseToShop,
                  PRODUCTION_STATUS.dateApproved,
                  PRODUCTION_STATUS.tagged,
                  PRODUCTION_STATUS.inProduction,
                  PRODUCTION_STATUS.sentToTMW,
                  PRODUCTION_STATUS.WIP,
                  PRODUCTION_STATUS.shipped,
                ].includes(status.id)
              )}
              valueExpr={'id'}
              displayExpr={'description'}
            />
          </Column>
          <Column dataField="ptiFiling" dataType="boolean" caption="PTI Filing" allowHeaderFiltering={false} />
          <Column
            dataField="bolNumber"
            allowEditing={true}
            caption="BOL"
            allowHeaderFiltering={false}
            editorOptions={{ maxLength: 15 }}
          />
          <Column
            dataField="truckReqDate"
            caption="Truck Requested date"
            dataType="date"
            format={'MM/dd/yyyy'}
            allowHeaderFiltering={false}
          />
          <Column
            dataField="deliverTo"
            caption="Deliver To"
            allowHeaderFiltering={false}
            setCellValue={setDeliveryPlantValue}
          >
            <Lookup dataSource={deliveryTypesLookup} valueExpr={'id'} displayExpr={'name'} />
          </Column>
          <Column dataField="detailer" caption="Detailer" allowEditing={false} allowHeaderFiltering={true} />
          <Column dataField="additionalItemsId" caption="Add to load" allowEditing={false} allowHeaderFiltering={false}>
            <Lookup
              dataSource={additionalItemsLookup}
              valueExpr={'id'}
              displayExpr={'description'}
              allowHeaderFiltering={false}
            />
          </Column>
          <Column dataField="deliveryNotes" caption="Delivery Notes" dataType="string" allowHeaderFiltering={false} />
          <Column
            dataField="custReqDate"
            caption="CR Date"
            dataType="date"
            format={'MM/dd/yyyy'}
            allowHeaderFiltering={false}
          />
        </DataGrid>
        <Legend />
        <Dialog open={dialogOpen}>
          <DialogContent>
            <DialogContentText>
              <Typography sx={{ fontWeight: 500, fontSize: '1.2rem' }}>{alertDialogContent}</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setDialogOpen(false)} sx={{ px: 4, border: 'solid 1px' }}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        {/* export to sap confirmation  */}
        <Dialog open={sapExportDialogOpen}>
          <DialogContent>
            <DialogContentText>
              <Typography sx={{ fontWeight: 500, fontSize: '1.2rem' }}>
                {'Are you sure to export the selected load(s)/schedule(s) to SAP?'}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={isSapExporting}
              startIcon={isSapExporting ? <CircularProgress size={20} sx={{ color: '#3862ae' }} /> : null}
              onClick={async () => {
                await handleExportToSAP();
              }}
              sx={{ px: 4, border: 'solid 1px', width: '100px' }}
            >
              Yes
            </Button>
            <Button
              color="error"
              disabled={isSapExporting}
              onClick={() => {
                setSapExportDialogOpen(false);
              }}
              sx={{ px: 4, border: 'solid 1px' }}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>

        <ActionBar
          sx={{
            justifyContent: 'end',
            display: 'flex',
            gap: '10px',
          }}
        >
          <ActionSubmitButton2 buttonText={'Save'} disabled={!hasEditData} onClick={handleSaveButton} />
          <ActionCancelButton
            buttonText={'cancel'}
            disabled={!hasEditData}
            clickHandler={() => dispatchScheduleDataGridRef.current.instance.cancelEditData()}
            width="3%"
          />
        </ActionBar>
      </Grid>
    </Grid>
  );
}

function Legend() {
  return (
    <Card sx={{ width: 'fit-content', my: 4 }} variant="outlined">
      <CardContent sx={{ width: 'fit-content' }}>
        <Typography
          sx={{
            position: 'relative',
            marginTop: '10px',
            px: 2,
            fontSize: '16px',
            height: 50,
            textAlign: 'left',
            alignItems: 'center',
            display: 'flex',
            border: 'solid 1px #f0f0f0',
          }}
        >
          <Box sx={{ position: 'absolute', backgroundColor: 'red', width: '5px', height: '100%', left: 0 }} />
          This indicates the load/schedule has cuts in different status.
        </Typography>
        <Typography
          sx={{
            position: 'relative',
            marginTop: '10px',
            px: 2,
            fontSize: '16px',
            height: 50,
            textAlign: 'left',
            alignItems: 'center',
            display: 'flex',
            color: '#aaaaaaaa',
            border: 'solid 1px #f0f0f0',
            fontStyle: 'italic',
          }}
        >
          This indicates that loads/schedules project is from different Plant.
        </Typography>
      </CardContent>
    </Card>
  );
}
